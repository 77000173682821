import React from "react";
import { Paper, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { BiBrain } from "react-icons/bi";
import { RiStarLine } from "react-icons/ri";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
    borderRadius: 12,
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    backgroundColor: "rgba(123, 44, 191, 0.04)",
    borderRadius: 8,
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    marginRight: theme.spacing(2),
  },
  title: {
    color: "#7B2CBF",
    fontWeight: 500,
    flex: 1,
  },
  content: {
    padding: theme.spacing(2),
    "& h1": {
      fontSize: "1.8rem",
      marginBottom: theme.spacing(2),
      fontWeight: 500,
      color: theme.palette.text.primary,
    },
    "& h2": {
      fontSize: "1.5rem",
      marginBottom: theme.spacing(2),
      fontWeight: 500,
      color: theme.palette.text.primary,
    },
    "& p": {
      marginBottom: theme.spacing(2),
      lineHeight: 1.6,
      color: theme.palette.text.secondary,
    },
    "& ul": {
      marginBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      color: theme.palette.text.secondary,
    },
    "& strong": {
      color: theme.palette.text.primary,
      fontWeight: 600,
    },
  },
  emptyState: {
    textAlign: "center",
    color: theme.palette.text.secondary,
    padding: theme.spacing(4),
  },
  disclaimer: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: "rgba(0, 0, 0, 0.02)",
    borderRadius: 8,
    border: "1px solid rgba(0, 0, 0, 0.08)",
  },
  disclaimerIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  disclaimerText: {
    color: theme.palette.text.secondary,
    fontSize: "0.875rem",
  },
}));

const formatMarkdown = (text) => {
  if (!text) return "";

  return (
    text
      // Convert headers
      .split("\n")
      .map((line) => {
        // Handle headers
        if (line.startsWith("# ")) {
          return `<h1>${line.substring(2)}</h1>`;
        }
        if (line.startsWith("## ")) {
          return `<h2>${line.substring(3)}</h2>`;
        }

        // Handle bullet points
        if (line.startsWith("* ")) {
          return `<li>${line.substring(2)}</li>`;
        }
        if (line.startsWith("- ")) {
          return `<li>${line.substring(2)}</li>`;
        }

        // Handle bold text with special cases
        line = line.replace(
          /\*\*(.*?)\*\*(:)?/g,
          (match, p1, p2) => `<strong>${p1}${p2 || ""}</strong>${p2 ? " " : ""}`
        );

        // Handle paragraphs
        if (line.trim()) {
          return `<p>${line}</p>`;
        }

        return line;
      })
      .join("\n")
  );
};

const AISummary = ({ summary }) => {
  const classes = useStyles();

  if (!summary) {
    return (
      <Paper className={classes.root}>
        <Box className={classes.emptyState}>
          <div
            className={classes.iconContainer}
            style={{ justifyContent: "center", marginBottom: 16 }}
          >
            <RiStarLine style={{ fontSize: 16, color: "#7B2CBF" }} />
            <BiBrain style={{ fontSize: 24, color: "#7B2CBF" }} />
            <RiStarLine style={{ fontSize: 16, color: "#7B2CBF" }} />
          </div>
          <Typography variant="body1">
            No AI health summary available.
          </Typography>
        </Box>
      </Paper>
    );
  }

  const formattedContent = formatMarkdown(summary);

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <div className={classes.iconContainer}>
          <RiStarLine style={{ fontSize: 16, color: "#7B2CBF" }} />
          <BiBrain style={{ fontSize: 24, color: "#7B2CBF" }} />
          <RiStarLine style={{ fontSize: 16, color: "#7B2CBF" }} />
        </div>
        <Typography variant="h6" className={classes.title}>
          AI Health Analysis
        </Typography>
      </div>
      <div className={classes.disclaimer}>
        <InfoIcon className={classes.disclaimerIcon} />
        <Typography className={classes.disclaimerText}>
          This AI-generated summary is based on the patient's health record data
          from the last 7 days. It should be used as a supplementary tool and
          not as a replacement for professional medical judgment.
        </Typography>
      </div>
      <div
        className={classes.content}
        dangerouslySetInnerHTML={{ __html: formattedContent }}
      />
    </Paper>
  );
};

export default AISummary;
