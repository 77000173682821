import React, { useEffect, useState } from "react";
import Axios from "axios";
import DashboardMui from "../dashboard/DashboardMui";
import swal from "sweetalert";
import { useParams, useHistory, useLocation, Redirect } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import TabScrollable from "./TabScrollable";
import patientIcon from "../../images/paciente2.svg";
import useUserState from "../hooks/useUserState";
import PatientEdition from "./PatientEdition";
import useBDocTranslator from "../hooks/useBDocTranslator";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function PatientEdit(props) {
  let { id } = useParams();
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const userState = useUserState();
  const [prescriptionData, setPrescriptionData] = useState([]);
  const [consultationNotes, setConsultationNotes] = useState([]);
  const [healthConditions, setHealthConditions] = useState([]);
  const [patientId, setPatientId] = useState("");
  const [refreshData, setRefreshData] = useState(false);
  const [patientData, setPatientData] = useState([]);
  const [healthMeasurement, setHealthMeasurement] = useState([]);
  const [dependants, setDependants] = useState([]);
  const [vaccines, setVaccines] = useState([]);
  const [bgPersonal, setBgPersonal] = useState([]);
  const [bgFamily, setBgFamily] = useState([]);
  const [appointmentFiles, setAppointmentFiles] = useState([]);
  const location = useLocation();

  useEffect(() => {
    if (userState[0] === undefined) {
      history.push({
        pathname: "/admin/login",
        state: { from: location.pathname },
      });
    }
  }, []);

  const appointmentId = location?.state?.appointmentId;

  const { t } = useBDocTranslator();

  const handleBack = () => {
    history.goBack();
  };

  //Used by Backdrop to unlock the screen
  const handleClose = () => {
    setOpen(false);
  };

  const handleRefreshData = () => {
    setRefreshData(!refreshData);
  };

  //Effect to fetch Data for Current Patient
  //First Request Patiend Data
  async function fetchPatientInfo() {
    const response = await Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/patient/findById/${id}`,
      {
        headers: {
          Authorization: "Bearer " + userState[0]?.jwt,
        },
      }
    );
    setPatientId(response.data.id);
    setPatientData(response.data);
    return response;
  }

  // Request Prescriptions related to Patient Selected
  async function fetchPrescription(idPatient) {
    //Then Requesting Prescriptions
    Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/prenscriptions/findByPatient?patientId=${idPatient}`,
      {
        headers: {
          Authorization: "Bearer " + userState[0]?.jwt,
        },
      }
    )
      .then((res) => {
        setLoading(false);
        setPrescriptionData(res.data);
      })
      .catch((err) => console.log(err));
  }

  // Request consultationNotes related to Patient
  async function fetchConsultationNotes(idPatient) {
    Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/consultations/findByPatient?patientId=${idPatient}`,
      {
        headers: {
          Authorization: "Bearer " + userState[0]?.jwt,
        },
      }
    )
      .then((res) => {
        setLoading(false);
        setConsultationNotes(res.data);
      })
      .catch((err) => console.log(err));
  }

  // Request Health Conditions
  async function fetchHealthConditions(idPatient) {
    Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/healthconditions/findByPatient?patient_id=${idPatient}`,
      {
        headers: {
          Authorization: "Bearer " + userState[0]?.jwt,
        },
      }
    )
      .then((res) => {
        setLoading(false);
        setHealthConditions(res.data);
      })
      .catch((err) => console.log(err));
  }

  // Request Health Measurement
  async function fetchHealthMeasurements(idPatient) {
    Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/measurement/findByPatient?patient_id=${idPatient}`,
      {
        headers: {
          Authorization: "Bearer " + userState[0]?.jwt,
        },
      }
    )
      .then((res) => {
        setLoading(false);
        setHealthMeasurement(res.data);
      })
      .catch((err) => console.log(err));
  }

  async function fetchDependants(idPatient) {
    Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/patient/findDependant?patientId=${idPatient}`,
      {
        headers: {
          Authorization: "Bearer " + userState[0]?.jwt,
        },
      }
    )
      .then((res) => {
        setLoading(false);
        setDependants(res.data);
      })
      .catch((err) => console.log(err));
  }

  async function fetchVaccines(idPatient) {
    Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/ehr/findByPatientId?patient_id=${idPatient}`,
      {
        headers: {
          Authorization: "Bearer " + userState[0]?.jwt,
        },
      }
    )
      .then((res) => {
        setLoading(false);
        setVaccines(res.data);
      })
      .catch((err) => console.log(err));
  }

  async function fetchAppointmentFiles(idPatient) {
    Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/appointments/files?patient_id=${idPatient}`,
      {
        headers: {
          Authorization: "Bearer " + userState[0]?.jwt,
        },
      }
    )
      .then((res) => {
        setLoading(false);
        setAppointmentFiles(res.data);
      })
      .catch((err) => console.log(err));
  }

  async function fetchBackgrounds(idPatient) {
    await Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/bg/all?patient=${idPatient}`,
      {
        headers: {
          Authorization: "Bearer " + userState[0]?.jwt,
        },
      }
    )
      .then((res) => {
        setLoading(false);
        setBgFamily(res.data?.family || []);
        setBgPersonal(res.data?.personal || []);
      })
      .catch((err) => console.log(err));
  }
  //Functions Calls
  async function fetchData() {
    if (userState[0] === undefined) return;
    const patientResult = await fetchPatientInfo();
    await fetchPrescription(patientResult.data.id);
    await fetchConsultationNotes(patientResult.data.id);
    await fetchHealthConditions(patientResult.data.id);
    await fetchHealthMeasurements(patientResult.data.id);
    await fetchDependants(patientResult.data.id);
    await fetchVaccines(patientResult.data.id);
    await fetchAppointmentFiles(patientResult.data.id);
    await fetchBackgrounds(patientResult.data.id);
  }

  const recall = {
    fetchPatientInfo,
    fetchPrescription,
    fetchConsultationNotes,
    fetchHealthConditions,
    fetchHealthMeasurements,
    fetchDependants,
    fetchVaccines,
    fetchAppointmentFiles,
    fetchBackgrounds,
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [refreshData]);

  const handleVisibility = (id, visibility) => {
    Axios.put(
      `${process.env.REACT_APP_API_URL_BASE}/consultations/updateVisibility?id=${id}`,
      {
        visibility: visibility,
      },
      {
        headers: {
          Authorization: "Bearer " + userState[0]?.jwt,
        },
      }
    )
      .then((res) => {
        swal("Success", "Consultation visibility updated", "success");
        setRefreshData(!refreshData);
      })
      .catch((err) => console.log(err));
  };

  const handleDeleteCondition = (id) => {
    if (id) {
      Axios.delete(
        `${process.env.REACT_APP_API_URL_BASE}/healthconditions/delete/${id}`,
        {
          headers: {
            Authorization: "Bearer " + userState[0]?.jwt,
          },
        }
      )
        .then((res) => {
          swal({
            tittle: "Success!",
            text: t("Condition Deleted"),
            icon: "success",
            button: false,
            timer: 3000,
          });
          fetchHealthConditions(patientId);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <DashboardMui
      sectionTitle="Edit Patient info"
      description="Editing Patient personal info, you can change any field related to patient selected"
      icon={patientIcon}
    >
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <PatientEdition patientData={patientData} />
      {/* Top Menu for Patient Details section */}
      {userState[0]?.user.role.name !== "NURSE" && (
        <div className="container-fluid pt-5">
          <TabScrollable
            prescription={prescriptionData}
            consultationNotes={consultationNotes}
            patientEmail={email}
            appointmentId={appointmentId}
            healthConditions={healthConditions}
            patientId={patientId}
            handleRefreshData={handleRefreshData}
            healthMeasurement={healthMeasurement}
            dependants={dependants}
            vaccines={vaccines}
            handleVisibility={handleVisibility}
            appointmentFiles={appointmentFiles}
            handleDeleteCondition={handleDeleteCondition}
            bgFamily={bgFamily}
            bgPersonal={bgPersonal}
            recall={recall}
            patientData={patientData}
          />
        </div>
      )}
    </DashboardMui>
  );
}

export default PatientEdit;
