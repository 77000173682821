import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import axios from "axios";
import useUserState from "../hooks/useUserState";
import useBDocTranslator from "../hooks/useBDocTranslator";
import swal from "sweetalert";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
  formControl: {
    minWidth: "100%",
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
}));

const OfflinePaymentForm = ({ plan, onSuccess }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [pendingAffiliationId, setPendingAffiliationId] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [formData, setFormData] = useState({
    payment_method_id: "",
    reference: "",
    origin_reference: "",
    comments: "",
    amount: plan?.price || "",
  });

  const [userData] = useUserState();
  const { t, changeDirectLanguage } = useBDocTranslator();

  useEffect(() => {
    checkExistingPendingAffiliation();
    fetchPaymentMethods();
    changeDirectLanguage("es");
  }, []);

  const fetchPaymentMethods = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_BASE}/paymentMethods`
      );
      if (response.data) {
        setPaymentMethods(response.data);
      }
    } catch (error) {
      console.error("Error fetching payment methods:", error);
      setError(t("Error loading payment methods"));
    }
  };

  const checkExistingPendingAffiliation = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_BASE}/affiliations/getPendingAffiliation`,
        {
          params: {
            patientId: userData?.patientId,
            planId: plan.id,
          },
          headers: {
            Authorization: `Bearer ${userData.jwt}`,
          },
        }
      );

      if (response.data?.id) {
        setPendingAffiliationId(response.data.id);
      }
    } catch (error) {
      console.error("Error checking pending affiliation:", error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const createPendingAffiliation = async () => {
    if (pendingAffiliationId) {
      return { id: pendingAffiliationId };
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_BASE}/affiliations/createAffiliation`,
        {
          patientId: userData?.patientId,
          planId: plan.id,
          status: "pending",
          affiliation_dt_start: new Date(),
        },
        {
          headers: {
            Authorization: `Bearer ${userData.jwt}`,
          },
        }
      );
      setPendingAffiliationId(response.data.id);
      return response.data;
    } catch (error) {
      console.error("Error creating affiliation:", error);
      throw new Error(
        error.response?.data?.message || t("Error creating affiliation")
      );
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");

    try {
      setLoading(true);
      const affiliation = await createPendingAffiliation();

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_BASE}/offlinePayments/save`,
        {
          patient_id: userData?.patientId,
          payment_method_id: formData.payment_method_id,
          affiliation_id: affiliation.id,
          reference: formData.reference,
          origin_reference: formData.origin_reference,
          comments: formData.comments,
          amount: Number(formData.amount),
        },
        {
          headers: {
            Authorization: `Bearer ${userData.jwt}`,
          },
        }
      );

      if (response.status === 201) {
        await swal({
          title: t("Success!"),
          text: t(
            "Your offline payment has been reported successfully. Our team will verify it shortly."
          ),
          icon: "success",
        });
        onSuccess && onSuccess();
      }
    } catch (error) {
      setError(t("Failed to report payment. Please try again."));
      console.error("Error in payment process:", error);
      await swal({
        title: t("Error"),
        text: t("Failed to report payment. Please try again."),
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      {error && (
        <Alert severity="error" className={classes.alert}>
          {error}
        </Alert>
      )}

      <Tooltip
        title={t("Select the method you used to make the payment")}
        placement="top"
        arrow
      >
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel>{t("Payment Method")}</InputLabel>
          <Select
            name="payment_method_id"
            value={formData.payment_method_id}
            onChange={handleChange}
            label={t("Payment Method")}
            required
          >
            {paymentMethods.map((method) => (
              <MenuItem key={method.id} value={method.id}>
                {method.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Tooltip>

      <Tooltip
        title={t("Enter the transaction reference number or receipt number")}
        placement="top"
        arrow
      >
        <TextField
          label={t("Reference Number")}
          name="reference"
          value={formData.reference}
          onChange={handleChange}
          variant="outlined"
          required
          fullWidth
        />
      </Tooltip>

      <Tooltip
        title={t("Enter any additional reference number (optional)")}
        placement="top"
        arrow
      >
        <TextField
          label={t("Origin Reference")}
          name="origin_reference"
          value={formData.origin_reference}
          onChange={handleChange}
          variant="outlined"
          fullWidth
        />
      </Tooltip>

      <Tooltip
        title={t("Enter the amount you paid for the plan")}
        placement="top"
        arrow
      >
        <TextField
          label={t("Amount")}
          name="amount"
          type="number"
          value={formData.amount}
          onChange={handleChange}
          variant="outlined"
          required
          fullWidth
          inputProps={{ min: 0 }}
        />
      </Tooltip>

      <Tooltip
        title={t("Add any additional information about your payment")}
        placement="top"
        arrow
      >
        <TextField
          label={t("Comments")}
          name="comments"
          value={formData.comments}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          multiline
          rows={4}
        />
      </Tooltip>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={loading}
        className={classes.submitButton}
      >
        {loading ? t("Submitting...") : t("Report Offline Payment")}
      </Button>
    </form>
  );
};

export default OfflinePaymentForm;
