import React, { useRef, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import useUserState from "../hooks/useUserState";
import Axios from "axios";
import useBDocTranslator from "../hooks/useBDocTranslator";
import Papa from "papaparse";
import {
  Button,
  CircularProgress,
  Typography,
  Divider,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import GetAppIcon from "@material-ui/icons/GetApp";
import WarningIcon from "@material-ui/icons/Warning";
import { Link } from "react-router-dom";

export default function ModalAddBulkPatient(props) {
  const classes = useStyles();
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const [patients, setPatients] = useState("");
  const [response, setResponse] = useState();
  const userLocalState = useUserState();
  const { t } = useBDocTranslator();
  const hiddenFileInput = useRef(null);

  const createUsers = () => {
    setLoading(true);
    Axios.post(
      `${process.env.REACT_APP_API_URL_BASE}/patient/create/bulk`,
      {
        patients,
      },
      {
        headers: { Authorization: "Bearer " + userLocalState[0].jwt },
      }
    )
      .then((res) => {
        setLoading(false);
        setFileName("");
        setPatients();
        setResponse(res.data);
        if (props.recall) {
          props.recall();
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFileName(file.name);

    if (file) {
      Papa.parse(file, {
        complete: (result) => {
          setPatients(result.data);
        },
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
      });
    }
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <Modal
      aria-labelledby="bulk-patient-upload"
      aria-describedby="bulk-patient-upload-modal"
      className={classes.modal}
      open={props.open}
      onClose={props.close}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <div className={classes.paper}>
          <Typography variant="h5" component="h2" gutterBottom>
            {t("Create Patients")}
          </Typography>

          <Divider className={classes.divider} />

          <div className={classes.content}>
            {/* Template Download Section */}
            <div className={classes.section}>
              <Typography variant="subtitle1" gutterBottom>
                {t("File Template")}:
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<GetAppIcon />}
                component={Link}
                to="/files/patients_template.xlsx"
                target="_blank"
                download
                className={classes.templateButton}
              >
                {t("Download template")}
              </Button>
            </div>

            {/* Warning Messages */}
            <div className={classes.warningSection}>
              <div className={classes.warningBox}>
                <WarningIcon color="action" />
                <Typography variant="body2">
                  {t("Only .csv files are allowed")}
                </Typography>
              </div>

              <div className={classes.warningBox}>
                <WarningIcon color="action" />
                <Typography variant="body2">
                  {t(
                    "If you want to add a plan to a patient, you must have the plan ID of zero price (0)"
                  )}
                </Typography>
              </div>
            </div>

            {/* Response Section */}
            {response && (
              <div className={classes.responseContainer}>
                <div className={classes.responseHeader}>
                  <Typography variant="h6">{"RESPONSE"}</Typography>
                  <ClearIcon
                    onClick={() => {
                      setFileName("");
                      props.close();
                      setResponse();
                      setPatients();
                    }}
                    className={classes.clearIcon}
                  />
                </div>
                <div className={classes.responseContent}>
                  {Object.keys(response).map((k) => {
                    const values = response[k];
                    if (!values) return null;
                    return (
                      <div key={k} className={classes.responseGroup}>
                        <Typography
                          variant="subtitle2"
                          className={classes.responseTitle}
                        >
                          {k}:
                        </Typography>
                        {values.map((v, idx) => (
                          <div key={idx} className={classes.responseItem}>
                            {`${v.nombre} | ${v.email} | ${
                              v.error ? t(v.error) : t("User created")
                            }`}
                          </div>
                        ))}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {/* File Upload Section */}
            {fileName ? (
              <div className={classes.fileInfo}>
                <Typography variant="body1">{fileName}</Typography>
                <ClearIcon
                  onClick={() => {
                    setFileName("");
                    setPatients();
                  }}
                  className={classes.clearIcon}
                />
              </div>
            ) : (
              !response && (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<CloudUploadIcon />}
                  onClick={handleClick}
                  className={classes.uploadButton}
                >
                  {t("Choose CSV File")}
                </Button>
              )
            )}

            <input
              accept=".csv"
              type="file"
              onChange={handleFileChange}
              ref={hiddenFileInput}
              style={{ display: "none" }}
            />

            {/* Patients Preview */}
            {fileName && patients && (
              <>
                <div className={classes.previewContainer}>
                  <Typography variant="subtitle2" gutterBottom>
                    {t("Preview")}:
                  </Typography>
                  <div className={classes.preview}>
                    {patients.map((p, idx) => (
                      <div key={idx} className={classes.previewItem}>
                        {Object.keys(p)
                          .map((k) => p[k])
                          .join(" - ")}
                      </div>
                    ))}
                  </div>
                </div>

                <div className={classes.actionButton}>
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={createUsers}
                      disabled={loading}
                    >
                      {t("Create")}
                    </Button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3),
    width: "90%",
    maxWidth: 800,
    maxHeight: "90vh",
    overflow: "auto",
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
  section: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  templateButton: {
    textTransform: "none",
  },
  warningSection: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  warningBox: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    padding: theme.spacing(1.5),
    backgroundColor: "#fff3cd",
    borderRadius: theme.shape.borderRadius,
    border: "1px solid #ffeeba",
  },
  responseContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
  },
  responseHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  responseContent: {
    maxHeight: 200,
    overflow: "auto",
  },
  responseGroup: {
    marginBottom: theme.spacing(2),
  },
  responseTitle: {
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
  responseItem: {
    padding: theme.spacing(0.5),
    "&:nth-child(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  fileInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.action.hover,
    borderRadius: theme.shape.borderRadius,
  },
  clearIcon: {
    cursor: "pointer",
    color: theme.palette.text.secondary,
    "&:hover": {
      color: theme.palette.error.main,
    },
  },
  uploadButton: {
    alignSelf: "center",
    textTransform: "none",
  },
  previewContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
  },
  preview: {
    maxHeight: 150,
    overflow: "auto",
    "& > div": {
      padding: theme.spacing(0.5),
      "&:nth-child(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
  actionButton: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
}));
