import React, { useEffect, useMemo, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import StarsComponent from "../utils/StarsComponent";
import { useLocation, useParams } from "react-router-dom";
import RatingComponent from "./RatingComponent";
import useBDocTranslator from "../hooks/useBDocTranslator";
import {
  Button,
  Card,
  CardContent,
  Typography,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import useUserState from "../hooks/useUserState";
import axios from "axios";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Rating() {
  const location = useLocation();
  const { rateProvider } = RatingComponent();
  const { t, changeDirectLanguage } = useBDocTranslator();
  const [providerFullname, setProviderFullname] = useState();
  const [profileImage, setProfileImage] = useState();
  const [currentRate, setCurrentRate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const { id } = useParams();
  const user = useUserState();
  const userLocalState = useMemo(() => {
    if (!user) return;
    return user[0];
  }, [user]);
  const jwtSession = userLocalState?.jwt;

  const fetchAllProviders = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_BASE}/appointments/findOne?id=${
          location.state?.appointmentId || id
        }`,
        {
          headers: { Authorization: "Bearer " + jwtSession },
        }
      );

      if (!response.data) return;
      setProviderFullname(
        `${response.data.provider?.firstName} ${response.data.provider?.lastname}`
      );
      if (response.data.provider?.user?.user_images?.length > 0) {
        setProfileImage(
          response.data.provider?.user?.user_images[0].path_resource
        );
      }
    } catch (error) {
      console.log(error);
      setSnackbarMessage(t("Error loading provider information"));
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handleRateProvider = async () => {
    try {
      setLoading(true);
      await rateProvider(currentRate, location.state?.appointmentId || id);
      setSnackbarMessage(t("Rating submitted successfully"));
      setSnackbarSeverity("success");
      setOpenSnackbar(true);
    } catch (error) {
      console.log(error);
      setSnackbarMessage(
        error.response?.status === 400
          ? error.response.data.message || t("Error submitting rating")
          : t("Error submitting rating")
      );
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const getRatingText = (rate) => {
    switch (rate) {
      case 1:
        return { text: t("Very bad"), color: "error.main" };
      case 2:
        return { text: t("Bad"), color: "error.main" };
      case 3:
        return { text: t("Good"), color: "warning.main" };
      case 4:
        return { text: t("Very good"), color: "success.main" };
      case 5:
        return { text: t("Excellent"), color: "success.main" };
      default:
        return { text: t("Not rated"), color: "text.secondary" };
    }
  };

  useEffect(() => {
    fetchAllProviders();
    changeDirectLanguage("es");
  }, []);

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-center">
        <Card
          className="shadow-lg"
          style={{ maxWidth: "450px", width: "100%" }}
        >
          <CardContent>
            <Typography variant="h5" component="h2" gutterBottom>
              {t("Rate your experience")}
            </Typography>

            <div className="text-center mb-4">
              <Avatar
                alt={providerFullname}
                src={profileImage}
                style={{
                  width: "120px",
                  height: "120px",
                  margin: "0 auto",
                  border: "3px solid #085279a1",
                  backgroundColor: "#f5f5f5",
                }}
              />
              <Typography variant="h6" className="mt-3" color="textSecondary">
                {providerFullname}
              </Typography>
            </div>

            <div className="mb-4">
              <StarsComponent
                ratingChanged={(newRating) => {
                  setCurrentRate(newRating);
                }}
                editRate={true}
              />
            </div>

            <div className="text-center mb-4">
              <Typography
                variant="body1"
                component="div"
                className="d-flex align-items-center justify-content-center"
              >
                {t("Your rating")}:&nbsp;
                <Typography
                  variant="body1"
                  component="span"
                  color={getRatingText(currentRate).color}
                  style={{ fontWeight: 500 }}
                >
                  {getRatingText(currentRate).text}
                </Typography>
              </Typography>
            </div>

            <div className="d-flex justify-content-center">
              <Button
                variant="contained"
                color="primary"
                onClick={handleRateProvider}
                disabled={currentRate === null || loading}
                size="large"
                style={{ minWidth: "150px" }}
              >
                {loading ? t("Saving...") : t("Submit Rating")}
              </Button>
            </div>

            <Typography
              variant="body2"
              color="textSecondary"
              className="text-center mt-4"
            >
              {t("Your feedback helps us improve our service quality")}
            </Typography>
          </CardContent>
        </Card>
      </div>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
