import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import consultationIcon from "../../images/consultation-icon.svg";
import useUserState from "../hooks/useUserState";
import Axios from "axios";
import ConsultationNotesDialog from "../prescription/ConsultationNotesDialog";
import useBDocTranslator from "../hooks/useBDocTranslator";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3),
    width: "90%",
    maxWidth: 900,
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(3),
    "& img": {
      width: 60,
      marginRight: theme.spacing(2),
    },
  },
}));

export default function ConsultationNotesModalForm(props) {
  const classes = useStyles();
  const [notes, setNotes] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [apptSelected, setApptSelected] = useState();
  const [visible, setVisible] = useState(true);
  const userLocalState = useUserState();
  const { t } = useBDocTranslator();

  const handleNotes = (e) => {
    setNotes(e.target.value);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const showNotification = (message, severity = "success") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  const handleNotesSave = async () => {
    if (!notes) {
      showNotification(t("Please enter consultation notes"), "error");
      return;
    }

    try {
      const payload = {
        note: notes,
        created_by_userId: userLocalState[0].extendedData.id,
        visibility: visible ? "public" : "private",
      };

      // Only include appointment_id if it exists
      if (apptSelected?.id) {
        payload.appointment_id = apptSelected.id;
      }
      payload.patient_id = props.patientId;

      const response = await Axios.post(
        `${process.env.REACT_APP_API_URL_BASE}/consultations/createOnly`,
        payload,
        {
          headers: { Authorization: "Bearer " + userLocalState[0].jwt },
        }
      );

      showNotification(t("Note created successfully"));
      setNotes("");
      if (props.handleRefreshData) {
        props.handleRefreshData();
      }

      // Optional: Close modal after successful save
      if (props.handleClose) {
        setTimeout(props.handleClose, 1000);
      }
    } catch (error) {
      console.error(error);
      showNotification(
        error.response?.data?.message || t("Error creating note"),
        "error"
      );
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="consultation-notes-modal"
        aria-describedby="create-consultation-notes"
        className={classes.modal}
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <div className={classes.paper}>
            <div className={classes.header}>
              <img src={consultationIcon} alt="consultation icon" />
              <h3>{t("Create Consultation Notes")}</h3>
            </div>

            <ConsultationNotesDialog
              handleConsultationNotes={handleNotes}
              consultationNotesValue={notes}
              handleSaveConsultationNotes={handleNotesSave}
              visible={visible}
              handleVisibility={setVisible}
              setApptSelected={setApptSelected}
              apptSelected={apptSelected}
              disabled={!notes} // Only disable if there are no notes
              patientId={props.patientId}
            />

            <Snackbar
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
            >
              <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
