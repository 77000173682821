import React, { forwardRef, useMemo, useState } from "react";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import HealthImage from "../../images/healthcondition.svg";
import useBDocTranslator from "../hooks/useBDocTranslator";
import EditIcon from "@material-ui/icons/Edit";
import {
  measurementPrompt,
  measurementTypes,
} from "../../const/healthMeasurement";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { makeStyles } from "@material-ui/core";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { format, parseISO } from "date-fns";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box,
} from "@material-ui/core";
import TimelineIcon from "@material-ui/icons/Timeline";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import useUserState from "../hooks/useUserState";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  EcgActions: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "1em",
  },
  actionIcon: {
    cursor: "pointer",
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: 0,
    padding: theme.spacing(2),
  },
  chartContainer: {
    width: "100%",
    height: "400px",
    padding: theme.spacing(2),
  },
  summaryBox: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.spacing(1),
  },
  legendContainer: {
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  legendItem: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  legendColor: {
    width: 16,
    height: 16,
    borderRadius: "50%",
  },
}));

function Healthmeasurement(props) {
  const { t } = useBDocTranslator();
  const classes = useStyles();
  const [openChart, setOpenChart] = useState(false);
  const [selectedMeasurement, setSelectedMeasurement] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [summaryData, setSummaryData] = useState(null);
  const [userData] = useUserState();
  const handleOpenChart = async (measurementType, patientId) => {
    try {
      const [measurementsResponse, summaryResponse] = await Promise.all([
        axios.get(
          `${process.env.REACT_APP_API_URL_BASE}/reports/health-measurements`,
          {
            params: {
              patientId,
              measurementType,
            },
            headers: {
              Authorization: `Bearer ${userData.jwt}`,
            },
          }
        ),
        axios.get(
          `${process.env.REACT_APP_API_URL_BASE}/reports/health-measurements/summary`,
          {
            params: {
              patientId,
              measurementType,
            },
            headers: {
              Authorization: `Bearer ${userData.jwt}`,
            },
          }
        ),
      ]);

      setChartData(measurementsResponse.data.data);
      setSummaryData(summaryResponse.data.data);
      setSelectedMeasurement(measurementType);
      setOpenChart(true);
    } catch (error) {
      console.error("Error fetching measurement data:", error);
    }
  };

  const dataFormated = useMemo(() => {
    if (!props.data) return [];
    return props.data
      .map((i) => {
        const mt = t(
          measurementTypes.find((mt) => mt.value === i.measurement_type)?.label
        );
        if (i.measurement_type === "ECG") {
          return {
            ...i,
            measurement_type: mt,
            result: (
              <span>
                {i.result.split("/*/")?.length
                  ? i.result.split("/*/")[0]
                  : "None"}
              </span>
            ),
            actions: (
              <div className={classes.EcgActions}>
                <CloudDownloadIcon
                  className={classes.actionIcon}
                  onClick={() => {
                    const parts = i.result.split("/*/");
                    const downloadUrl = parts[1]?.trim();
                    if (downloadUrl) {
                      window.open(downloadUrl, "_blank");
                    }
                  }}
                />
                {props.type === "pro" && (
                  <EditIcon
                    className={classes.actionIcon}
                    onClick={() => {
                      if (props.openEdit) props.openEdit(i);
                    }}
                  />
                )}
                <TimelineIcon
                  className={classes.actionIcon}
                  onClick={() =>
                    handleOpenChart(i.measurement_type, i.patient_id)
                  }
                />
              </div>
            ),
          };
        }
        return {
          ...i,
          measurement_type: mt,
          result: measurementPrompt[i.measurement_type]
            ? `${i.result} ${measurementPrompt[i.measurement_type]}`
            : i.result,
          actions: (
            <div className={classes.EcgActions}>
              {props.type === "pro" && (
                <EditIcon
                  className={classes.actionIcon}
                  onClick={() => {
                    if (props.openEdit) props.openEdit(i);
                  }}
                />
              )}
              <TimelineIcon
                className={classes.actionIcon}
                onClick={() =>
                  handleOpenChart(i.measurement_type, i.patient_id)
                }
              />
            </div>
          ),
        };
      })
      ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  }, [props.data]);

  const formatChartData = (data) => {
    if (!data || !data.labels) return [];

    return data.labels.map((label, index) => {
      const result = {
        date: label,
      };

      data.datasets.forEach((dataset) => {
        result[dataset.label] = dataset.data[index];
      });

      return result;
    });
  };

  const getChartColors = () => ({
    Systolic: "#8884d8",
    Diastolic: "#82ca9d",
    BP: "#8884d8",
    SpO2: "#82ca9d",
    BG: "#ffc658",
    ECG: "#ff7300",
    BT: "#ff4444",
  });

  return (
    <div style={{ maxWidth: "100%" }}>
      <MaterialTable
        columns={[
          { title: "Id", field: "id" },
          { title: t("Measurement"), field: "measurement_type" },
          { title: t("Result"), field: "result" },
          { title: t("Register date"), field: "created_at", type: "datetime" },
          { title: t("Actions"), field: "actions" },
        ]}
        data={dataFormated}
        title={t("Health Monitor Results")}
        icons={tableIcons}
        options={{
          headerStyle: {
            backgroundColor: "#01579b",
            color: "#FFF",
          },
          actionsColumnIndex: -1,
        }}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <span>
                <img style={{ width: "15%" }} src={HealthImage} alt="" />
              </span>
            ),
          },
        }}
      />

      <Dialog
        open={openChart}
        onClose={() => setOpenChart(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <Typography variant="h6">
            {t("Measurement History")} - {selectedMeasurement}
          </Typography>
          <IconButton onClick={() => setOpenChart(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className={classes.chartContainer}>
            {chartData && (
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  data={formatChartData(chartData)}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="date"
                    tickFormatter={(value) =>
                      format(parseISO(value), "MM/dd HH:mm")
                    }
                  />
                  <YAxis />
                  <Tooltip
                    labelFormatter={(value) => format(parseISO(value), "PPpp")}
                    formatter={(value) => [value, ""]}
                  />
                  <Legend />
                  {chartData.datasets.map((dataset) => (
                    <Line
                      key={dataset.label}
                      type="monotone"
                      dataKey={dataset.label}
                      stroke={getChartColors()[dataset.label]}
                      dot={{ r: 4 }}
                      activeDot={{ r: 8 }}
                    />
                  ))}
                </LineChart>
              </ResponsiveContainer>
            )}
          </div>

          {summaryData && (
            <Box className={classes.summaryBox}>
              <Typography variant="h6" gutterBottom>
                {t("Summary")}
              </Typography>
              <Typography>
                {t("Total Measurements")}: {summaryData.total_measurements}
              </Typography>
              <Typography>
                {t("Average")}: {Number(summaryData.avg_value).toFixed(2)}
                {summaryData.avg_secondary &&
                  ` / ${Number(summaryData.avg_secondary).toFixed(2)}`}
              </Typography>
              <Typography>
                {t("Range")}: {summaryData.min_value} - {summaryData.max_value}
                {summaryData.min_secondary &&
                  ` / ${summaryData.min_secondary} - ${summaryData.max_secondary}`}
              </Typography>
              <Typography>
                {t("Last Measurement")}:{" "}
                {format(new Date(summaryData.last_measurement_date), "PPpp")}
              </Typography>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Healthmeasurement;
