import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Axios from "axios";
import userLocalState from "./../hooks/useUserState";
import swal from "sweetalert";
import HConditionTable from "../patients/EHR/HConditionTable";
import ConsultationNotesEHR from "../prescription/ConsultationNotesEHR.";
import PrescriptionEHR from "../prescription/PrescriptionEHR";
import Prescriptions from "../patients/Prescriptions";
import Consultations from "../patients/Consultations";
import HealthConditionForm from "../patients/EHR/HealthConditionForm";
import Healthmeasurement from "../patients/Healthmeasurements";
import useBDocTranslator from "../hooks/useBDocTranslator";
import BackgroundTable from "../patients/BackgroundTable";
import BackgroundForm from "../patients/BackgroundForm";
import BackgroundFormEHR from "../patients/EHR/BackgroundFormEHR";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  headingNew: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
    color: "white",
  },
}));

export default function EHRaccordion(props) {
  const classes = useStyles();
  const localState = userLocalState();
  const [prescriptionData, setPrescriptionData] = useState([]);
  const [consultationNotes, setConsultationNotes] = useState([]);
  const [healthConditions, setHealthConditions] = useState([]);
  const [patientId, setPatientId] = useState("");
  const [refreshData, setRefreshData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [healthMeasurement, setHealthMeasurement] = useState([]);
  const [bgFamily, setBgFamily] = useState([]);
  const [bgPersonal, setBgPersonal] = useState([]);
  const { t } = useBDocTranslator();

  const handleRefreshData = () => {
    setRefreshData(!refreshData);
  };

  //Effect to fetch prenscriptions for Patient Selected
  useEffect(() => {
    setLoading(true);

    //First Obtaining Pat Data
    async function fetchPatientInfo(idPatient) {
      const response = await Axios.get(
        `${process.env.REACT_APP_API_URL_BASE}/patient/findById/${idPatient}`,
        {
          headers: {
            Authorization: "Bearer " + localState[0].jwt,
          },
        }
      );
      return response;
    }

    async function fetchPrescription(idPatient) {
      //Then Requesting Prescriptions
      Axios.get(
        `${process.env.REACT_APP_API_URL_BASE}/prenscriptions/findByPatient?patientId=${idPatient}`,
        {
          headers: {
            Authorization: "Bearer " + localState[0].jwt,
          },
        }
      )
        .then((res) => {
          setLoading(false);
          const dataNormalized = normalizePrescription(res.data);
          setPrescriptionData(dataNormalized);
        })
        .catch((err) => console.log(err));
    }

    async function fetchConsultationNotes(idPatient) {
      Axios.get(
        `${process.env.REACT_APP_API_URL_BASE}/consultations/findByPatient?patientId=${idPatient}`,
        {
          headers: {
            Authorization: "Bearer " + localState[0].jwt,
          },
        }
      )
        .then((res) => {
          setLoading(false);
          setConsultationNotes(res.data);
        })
        .catch((err) => console.log(err));
    }

    //Fetch Health Conditions
    async function fetchHealthConditions(idPatient) {
      Axios.get(
        `${process.env.REACT_APP_API_URL_BASE}/healthconditions/findByPatient?patient_id=${idPatient}`,
        {
          headers: {
            Authorization: "Bearer " + localState[0].jwt,
          },
        }
      )
        .then((res) => {
          setLoading(false);
          setHealthConditions(res.data);
        })
        .catch((err) => console.log(err));
    }

    // Request Health Measurement
    async function fetchHealthMeasurements(idPatient) {
      Axios.get(
        `${process.env.REACT_APP_API_URL_BASE}/measurement/findByPatient?patient_id=${idPatient}`,
        {
          headers: {
            Authorization: "Bearer " + localState[0].jwt,
          },
        }
      )
        .then((res) => {
          setLoading(false);
          setHealthMeasurement(res.data);
        })
        .catch((err) => console.log(err));
    }

    async function fetchBackgrounds(idPatient) {
      await Axios.get(
        `${process.env.REACT_APP_API_URL_BASE}/bg/all?patient=${idPatient}`,
        {
          headers: {
            Authorization: "Bearer " + localState[0]?.jwt,
          },
        }
      )
        .then((res) => {
          setLoading(false);
          setBgFamily(res.data?.family || []);
          setBgPersonal(res.data?.personal || []);
        })
        .catch((err) => console.log(err));
    }

    //Functions Calls
    async function fetchData() {
      const patientResult = await fetchPatientInfo(props.patientId);
      await fetchPrescription(patientResult.data.id);
      await fetchConsultationNotes(patientResult.data.id);
      await fetchHealthConditions(patientResult.data.id);
      await fetchHealthMeasurements(patientResult.data.id);
      await fetchBackgrounds(patientResult.data.id);
      setPatientId(patientResult.data.id);
    }

    fetchData();
  }, [refreshData]);

  // method to adapt the prescription response to the screen structure
  const normalizePrescription = (prescriptionData) => {
    let result = [];
    prescriptionData.forEach((item) => {
      if (item?.prescriptions?.length > 0) {
        for (let i = 0; i < item.prescriptions.length; i++) {
          const obj = {
            patient_directions: item.prescriptions[i].patient_directions,
            providerName:
              item.provider.firstName + " " + item.provider.lastname,
            created_at: item.prescriptions[i].created_at,
            pharmacy_note: item.prescriptions[i].pharmacy_note,
          };
          result.push(obj);
        }
      }
    });
    return result;
  };

  const handleVisibility = (id, visibility) => {
    Axios.put(
      `${process.env.REACT_APP_API_URL_BASE}/consultations/updateVisibility?id=${id}`,
      {
        visibility: visibility,
      },
      {
        headers: {
          Authorization: "Bearer " + localState[0].jwt,
        },
      }
    )
      .then((res) => {
        swal(t("Success!"), t("Consultation visibility updated"), "success");
        setRefreshData(!refreshData);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className={classes.root}>
      {/* Consultation Notes */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ backgroundColor: "#1e4f8c" }}
        >
          <Typography className={classes.headingNew}>
            {t("New Consultation Notes")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ConsultationNotesEHR
            {...props}
            handleRefreshData={handleRefreshData}
            handleVisibility={handleVisibility}
          />
        </AccordionDetails>
      </Accordion>
      {/* Prescriptions */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ backgroundColor: "#1e4f8c" }}
        >
          <Typography className={classes.headingNew}>
            {t("New Prescription")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PrescriptionEHR
            {...props}
            handleRefreshData={handleRefreshData}
            prescType={props.prescType}
            setPrescType={props.setPrescType}
          />
        </AccordionDetails>
      </Accordion>
      {/* Create New Health Condition */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
          aria-controls="register-healthcondition-panel"
          id="register-healthcondition-panel"
          style={{ backgroundColor: "#1e4f8c" }}
        >
          <Typography className={classes.headingNew}>
            {t("Include background information")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="row w-100">
            <HealthConditionForm
              patientId={props.patientId}
              handleRefreshData={handleRefreshData}
            />
          </div>
        </AccordionDetails>
      </Accordion>
      {/* New Personal Background */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
          aria-controls="background-panel"
          id="background-panel"
          style={{ backgroundColor: "#1e4f8c" }}
        >
          <Typography className={classes.headingNew}>
            {t("Include Personal Background")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <BackgroundFormEHR
            title={t("Include Personal Background")}
            patientId={patientId}
            handleRefreshData={handleRefreshData}
            type="personal"
          />
        </AccordionDetails>
      </Accordion>
      {/* New Family Background */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
          aria-controls="background-panel"
          id="background-panel"
          style={{ backgroundColor: "#1e4f8c" }}
        >
          <Typography className={classes.headingNew}>
            {t("Include Family Background")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <BackgroundFormEHR
            title={t("Include Family Background")}
            patientId={patientId}
            handleRefreshData={handleRefreshData}
            type="family"
          />
        </AccordionDetails>
      </Accordion>
      {/* Patient Health Record */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="health-conditions-panel"
          id="health-conditions-panel"
        >
          <Typography className={classes.heading}>
            {t("Health Conditions")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <HConditionTable
              data={healthConditions}
              conditionType="H-CONDITION"
              tableTitle="Health Conditions"
            />
          </div>
        </AccordionDetails>
      </Accordion>
      {/* Medications */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="medications-panel"
          id="medications-panel"
        >
          <Typography className={classes.heading}>
            {t("Medications")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <HConditionTable
              data={healthConditions}
              conditionType="MEDICATIONS"
              tableTitle="Medications"
            />
          </div>
        </AccordionDetails>
      </Accordion>
      {/* Allergies */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="allergies-panel"
          id="allergies-panel"
        >
          <Typography className={classes.heading}>{t("Allergies")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <HConditionTable
              data={healthConditions}
              conditionType="ALLERGIES"
              tableTitle="Allergies"
            />
          </div>
        </AccordionDetails>
      </Accordion>
      {/* Surgeries */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="surgeries-panel"
          id="surgeries-panel"
        >
          <Typography className={classes.heading}>{t("Surgeries")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <HConditionTable
              data={healthConditions}
              conditionType="SURGERIES"
              tableTitle="Surgeries"
            />
          </div>
        </AccordionDetails>
      </Accordion>
      {/* Hospitalizations */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="surgeries-panel"
          id="surgeries-panel"
        >
          <Typography className={classes.heading}>
            {t("Hospitalization")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <HConditionTable
              data={healthConditions}
              conditionType="HOSPITALIZATIONS"
              tableTitle="Hospitalizations"
            />
          </div>
        </AccordionDetails>
      </Accordion>
      {/* Prescription History */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="surgeries-panel"
          id="surgeries-panel"
        >
          <Typography className={classes.heading}>
            {t("Prescriptions History")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Prescriptions data={prescriptionData} />
          </div>
        </AccordionDetails>
      </Accordion>
      {/* Health Measurements */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="surgeries-panel"
          id="surgeries-panel"
        >
          <Typography className={classes.heading}>
            {t("Health Monitor History")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Healthmeasurement data={healthMeasurement} />
          </div>
        </AccordionDetails>
      </Accordion>
      {/* Consultation Notes History */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="surgeries-panel"
          id="surgeries-panel"
        >
          <Typography className={classes.heading}>
            {t("Consultation Notes History")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Consultations data={consultationNotes} />
          </div>
        </AccordionDetails>
      </Accordion>
      {/* Family Background */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="background-panel"
          id="background-panel"
        >
          <Typography className={classes.heading}>
            {t("Family Background")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <BackgroundTable
              data={bgFamily}
              handleRefreshData={handleRefreshData}
              type="pro"
            />
          </div>
        </AccordionDetails>
      </Accordion>
      {/* Personal Background */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="background-panel"
          id="background-panel"
        >
          <Typography className={classes.heading}>
            {t("Personal Background")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <BackgroundTable
              data={bgPersonal}
              handleRefreshData={handleRefreshData}
              type="pro"
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
