import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Snackbar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import DashboardAdmin from "../DashboardAdmin";
import useBDocTranslator from "../../hooks/useBDocTranslator";
import axios from "axios";
import useUserState from "../../hooks/useUserState";
import MuiAlert from "@material-ui/lab/Alert";
import { fade } from "@material-ui/core/styles/colorManipulator";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    backgroundColor: "#f5f5f5",
    minHeight: "100vh",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(3),
  },
  tableContainer: {
    backgroundColor: "#fff",
    borderRadius: theme.shape.borderRadius,
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 650,
  },
  tableHeader: {
    backgroundColor: fade(theme.palette.primary.main, 0.05),
    "& .MuiTableCell-head": {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
  },
  actionButton: {
    margin: theme.spacing(0, 0.5),
  },
  addButton: {
    borderRadius: 20,
    padding: theme.spacing(1, 3),
  },
  statusChip: {
    borderRadius: 12,
    padding: theme.spacing(0.5, 1.5),
    fontWeight: 500,
    textTransform: "capitalize",
  },
  dialog: {
    "& .MuiDialog-paper": {
      padding: theme.spacing(2),
    },
  },
  dialogTitle: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1, 0),
    minWidth: 120,
  },
}));

const StatusChip = ({ status }) => {
  const classes = useStyles();
  const backgroundColor = status === "enabled" ? "#e3f2fd" : "#ffebee";
  const color = status === "enabled" ? "#1976d2" : "#d32f2f";

  return (
    <Typography
      component="span"
      className={classes.statusChip}
      style={{ backgroundColor, color }}
    >
      {status}
    </Typography>
  );
};

export default function PracticeLocationManagement() {
  const classes = useStyles();
  const { t } = useBDocTranslator();
  const [locations, setLocations] = useState([]);
  const [countries, setCountries] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentLocation, setCurrentLocation] = useState({
    name: "",
    address: "",
    location: "",
    practice_type: "",
    status: "enabled",
    country_id: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const userState = useUserState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  useEffect(() => {
    fetchLocations();
    fetchCountries();
  }, []);

  const fetchLocations = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_BASE}/locations`,
        {
          headers: {
            Authorization: `Bearer ${userState[0].jwt}`,
          },
        }
      );
      setLocations(response.data);
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_BASE}/countries/findAll`,
        {
          headers: {
            Authorization: `Bearer ${userState[0].jwt}`,
          },
        }
      );
      setCountries(response.data);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  const handleOpen = () => {
    setOpen(true);
    setIsEditing(false);
    setCurrentLocation({
      name: "",
      address: "",
      location: "",
      practice_type: "",
      status: "enabled",
      country_id: "",
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setCurrentLocation({
      ...currentLocation,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    try {
      if (isEditing) {
        await axios.put(
          `${process.env.REACT_APP_API_URL_BASE}/locations/${currentLocation.id}`,
          currentLocation,
          {
            headers: {
              Authorization: `Bearer ${userState[0].jwt}`,
            },
          }
        );
        setSnackbarMessage(t("Location updated successfully"));
      } else {
        await axios.post(
          `${process.env.REACT_APP_API_URL_BASE}/locations`,
          currentLocation,
          {
            headers: {
              Authorization: `Bearer ${userState[0].jwt}`,
            },
          }
        );
        setSnackbarMessage(t("New location added successfully"));
      }
      setSnackbarSeverity("success");
      fetchLocations();
      handleClose();
    } catch (error) {
      console.error("Error saving location:", error);
      setSnackbarMessage(t("Error saving location. Please try again."));
      setSnackbarSeverity("error");
    }
    setSnackbarOpen(true);
  };

  const handleEdit = (location) => {
    setCurrentLocation(location);
    setIsEditing(true);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm(t("Are you sure you want to delete this location?"))) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_API_URL_BASE}/locations/${id}`,
          {
            headers: {
              Authorization: `Bearer ${userState[0].jwt}`,
            },
          }
        );
        fetchLocations();
      } catch (error) {
        console.error("Error deleting location:", error);
      }
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <DashboardAdmin>
      <div className={classes.root}>
        <div className={classes.header}>
          <Typography variant="h4" color="textSecondary">
            {t("Practice Locations Management")}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleOpen}
            className={classes.addButton}
          >
            {t("Add New Location")}
          </Button>
        </div>

        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableHeader}>
                <TableCell>{t("Name")}</TableCell>
                <TableCell>{t("Address")}</TableCell>
                <TableCell>{t("Location")}</TableCell>
                <TableCell>{t("Practice Type")}</TableCell>
                <TableCell>{t("Country")}</TableCell>
                <TableCell>{t("Status")}</TableCell>
                <TableCell align="right">{t("Actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {locations.map((row) => (
                <TableRow key={row.id} hover>
                  <TableCell>
                    <strong>{row.name}</strong>
                  </TableCell>
                  <TableCell>{row.address}</TableCell>
                  <TableCell>{row.location}</TableCell>
                  <TableCell>
                    {row.practice_type === "VIRTUAL_CLINIC"
                      ? t("Virtual Clinic")
                      : t("Physical Clinic")}
                  </TableCell>
                  <TableCell>{row.country ? row.country.name : ""}</TableCell>
                  <TableCell>
                    <StatusChip status={row.status} />
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      className={classes.actionButton}
                      color="primary"
                      size="small"
                      onClick={() => handleEdit(row)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      className={classes.actionButton}
                      color="secondary"
                      size="small"
                      onClick={() => handleDelete(row.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth="md"
          fullWidth
          className={classes.dialog}
        >
          <DialogTitle className={classes.dialogTitle}>
            <Typography variant="h6" color="primary">
              {isEditing ? t("Edit Location") : t("Add New Location")}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  margin="dense"
                  name="name"
                  label={t("Name")}
                  type="text"
                  fullWidth
                  value={currentLocation.name}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  name="address"
                  label={t("Address")}
                  type="text"
                  fullWidth
                  value={currentLocation.address}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  name="location"
                  label={t("Location")}
                  type="text"
                  fullWidth
                  value={currentLocation.location}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel>{t("Practice Type")}</InputLabel>
                  <Select
                    name="practice_type"
                    value={currentLocation.practice_type}
                    onChange={handleChange}
                    required
                  >
                    <MenuItem value="VIRTUAL_CLINIC">
                      {t("Virtual Clinic")}
                    </MenuItem>
                    <MenuItem value="PHYSICAL_CLINIC">
                      {t("Physical Clinic")}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel>{t("Country")}</InputLabel>
                  <Select
                    name="country_id"
                    value={currentLocation.country_id}
                    onChange={handleChange}
                    required
                  >
                    {countries.map((country) => (
                      <MenuItem key={country.id} value={country.id}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel>{t("Status")}</InputLabel>
                  <Select
                    name="status"
                    value={currentLocation.status}
                    onChange={handleChange}
                    required
                  >
                    <MenuItem value="enabled">{t("Enabled")}</MenuItem>
                    <MenuItem value="disabled">{t("Disabled")}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" color="primary">
              {t("Cancel")}
            </Button>
            <Button onClick={handleSubmit} variant="contained" color="primary">
              {isEditing ? t("Update") : t("Add")}
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={4000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={handleSnackbarClose}
            severity={snackbarSeverity}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </div>
    </DashboardAdmin>
  );
}
