import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CssBaseline, Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
    alignItems: "center",
    padding: "30px",
    backgroundColor: "white",
    boxShadow: "5px 5px 16px 1px #caced0",
    borderRadius: "10px",
  },
}));
export default function PlanBasicInfo({
  planInfo,
  setPlanInfo,
  planToEdit,
  setPlanToEdit,
}) {
  const classes = useStyles();

  return (
    // Material UI Form to capture name, description, price, status as a select, billing cycle as a select
    <Container component="main">
      <CssBaseline />
      <div className={classes.paper}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="planName">Plan Name</label>
              <input
                type="text"
                className="form-control"
                id="planName"
                name="planName"
                placeholder="Enter Plan Name"
                required={true}
                maxLength="50"
                value={planInfo.name || planToEdit?.name}
                onChange={(e) => {
                  // if planToEdit is not empty, then we are editing a plan
                  if (planToEdit?.id) {
                    setPlanToEdit({ ...planToEdit, name: e.target.value });
                    setPlanInfo({ ...planInfo, name: e.target.value });
                  }
                  // else we are creating a new plan
                  else {
                    setPlanInfo({ ...planInfo, name: e.target.value });
                  }
                }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="planDescription">Plan Description</label>
              <input
                type="text"
                className="form-control"
                id="planDescription"
                name="planDescription"
                placeholder="Enter Plan Description"
                required={true}
                maxLength="300"
                value={planInfo.description || planToEdit?.description}
                onChange={(e) => {
                  // if planToEdit is not empty, then we are editing a plan
                  if (planToEdit?.id) {
                    setPlanToEdit({
                      ...planToEdit,
                      description: e.target.value,
                    });
                    setPlanInfo({ ...planInfo, description: e.target.value });
                  }
                  // else we are creating a new plan
                  else {
                    setPlanInfo({ ...planInfo, description: e.target.value });
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="planPrice">Plan Price</label>
              <input
                type="number"
                className="form-control"
                id="planPrice"
                name="planPrice"
                max={5000}
                min={1}
                required={true}
                placeholder="Enter Plan Price"
                value={planInfo.price || planToEdit?.price}
                onChange={(e) => {
                  // if planToEdit is not empty, then we are editing a plan
                  if (planToEdit?.id) {
                    setPlanToEdit({ ...planToEdit, price: e.target.value });
                    setPlanInfo({ ...planInfo, price: e.target.value });
                  }
                  // else we are creating a new plan
                  else {
                    setPlanInfo({ ...planInfo, price: e.target.value });
                  }
                }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="planStatus">Plan Status</label>
              <select
                className="form-control"
                id="planStatus"
                name="planStatus"
                value={planInfo.status || planToEdit?.status}
                required={true}
                onChange={(e) => {
                  // if planToEdit is not empty, then we are editing a plan
                  if (planToEdit?.id) {
                    setPlanToEdit({ ...planToEdit, status: e.target.value });
                    setPlanInfo({ ...planInfo, status: e.target.value });
                  }
                  // else we are creating a new plan
                  else {
                    setPlanInfo({ ...planInfo, status: e.target.value });
                  }
                }}
              >
                <option selected={true} value="">
                  Select Status
                </option>
                <option value="enabled">Enabled</option>
                <option value="disabled">Disabled</option>
                <option value="hidden">Hidden</option>
                <option value="deleted">Deleted</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="billingCycle">Billing Cycle</label>
              <select
                className="form-control"
                id="billingCycle"
                name="billingCycle"
                value={planInfo.billing_cycle || planToEdit?.billing_cycle}
                onChange={(e) => {
                  // if planToEdit is not empty, then we are editing a plan
                  if (planToEdit?.id) {
                    setPlanToEdit({
                      ...planToEdit,
                      billing_cycle: e.target.value,
                    });
                    setPlanInfo({ ...planInfo, billing_cycle: e.target.value });
                  }
                  // else we are creating a new plan
                  else {
                    setPlanInfo({ ...planInfo, billing_cycle: e.target.value });
                  }
                }}
              >
                <option selected={true} value="">
                  Select Billing Cycle
                </option>
                <option value="monthly">Monthly</option>
                <option value="annually">Annually</option>
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="beneficiaries">Beneficiaries</label>
              <input
                type="number"
                className="form-control"
                id="beneficiaries"
                name="beneficiaries"
                placeholder="Enter Max beneficiaries allowed"
                required={true}
                min={1}
                max={20}
                value={planInfo.beneficiary_qty || planToEdit?.beneficiary_qty}
                onChange={(e) => {
                  // if planToEdit is not empty, then we are editing a plan
                  if (planToEdit?.id) {
                    setPlanToEdit({
                      ...planToEdit,
                      beneficiary_qty: e.target.value,
                    });
                    setPlanInfo({
                      ...planInfo,
                      beneficiary_qty: e.target.value,
                    });
                  }
                  // else we are creating a new plan
                  else {
                    setPlanInfo({
                      ...planInfo,
                      beneficiary_qty: e.target.value,
                    });
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
