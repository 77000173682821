import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import EditIcon from "@material-ui/icons/Edit";
import DashboardAdmin from "../DashboardAdmin";
import axios from "axios";
import useUserState from "../../hooks/useUserState";
import useBDocTranslator from "../../hooks/useBDocTranslator";
import swal from "sweetalert";
import SettingsIcon from "../../../images/settings-icon.svg";
import { fade } from "@material-ui/core/styles/colorManipulator";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    backgroundColor: "#f5f5f5",
    minHeight: "100vh",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(3),
  },
  warning: {
    marginBottom: theme.spacing(3),
    "& ul": {
      marginBottom: 0,
    },
  },
  tableContainer: {
    backgroundColor: "#fff",
    borderRadius: theme.shape.borderRadius,
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 650,
  },
  tableHeader: {
    backgroundColor: fade(theme.palette.primary.main, 0.05),
    "& .MuiTableCell-head": {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
  },
  actionButton: {
    margin: theme.spacing(0, 0.5),
  },
  statusChip: {
    borderRadius: 12,
    padding: theme.spacing(0.5, 1.5),
    fontWeight: 500,
    textTransform: "capitalize",
  },
  dialog: {
    "& .MuiDialog-paper": {
      padding: theme.spacing(2),
    },
  },
  dialogTitle: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(2),
  },
  toggleButton: {
    borderRadius: 20,
    padding: theme.spacing(0.5, 2),
    minWidth: 100,
  },
}));

const StatusChip = ({ status }) => {
  const classes = useStyles();
  const backgroundColor = status === "enabled" ? "#e3f2fd" : "#ffebee";
  const color = status === "enabled" ? "#1976d2" : "#d32f2f";

  return (
    <Typography
      component="span"
      className={classes.statusChip}
      style={{ backgroundColor, color }}
    >
      {status}
    </Typography>
  );
};

export default function ConfigurationManager() {
  const classes = useStyles();
  const userState = useUserState();
  const { t } = useBDocTranslator();
  const [configurations, setConfigurations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedConfig, setSelectedConfig] = useState(null);
  const [editedValue, setEditedValue] = useState("");

  const fetchConfigurations = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_BASE}/configuration`,
        {
          headers: {
            Authorization: `Bearer ${userState[0].jwt}`,
          },
        }
      );
      setConfigurations(response.data);
    } catch (error) {
      console.error("Error fetching configurations:", error);
      swal("Error", t("Error fetching configurations"), "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConfigurations();
  }, []);

  const handleEditClick = (config) => {
    setSelectedConfig(config);
    setEditedValue(config.config_value);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedConfig(null);
    setEditedValue("");
  };

  const handleUpdateConfiguration = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL_BASE}/configuration/updateStatus`,
        {
          id: selectedConfig.id,
          config_value: editedValue,
          status: selectedConfig.status,
        },
        {
          headers: {
            Authorization: `Bearer ${userState[0].jwt}`,
          },
        }
      );
      swal("Success", t("Configuration updated successfully"), "success");
      fetchConfigurations();
      handleCloseDialog();
    } catch (error) {
      console.error("Error updating configuration:", error);
      swal("Error", t("Error updating configuration"), "error");
    }
  };

  const handleToggleStatus = async (config) => {
    try {
      const newStatus = config.status === "enabled" ? "disabled" : "enabled";
      await axios.put(
        `${process.env.REACT_APP_API_URL_BASE}/configuration/updateStatus`,
        {
          id: config.id,
          status: newStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${userState[0].jwt}`,
          },
        }
      );
      swal("Success", t("Status updated successfully"), "success");
      fetchConfigurations();
    } catch (error) {
      console.error("Error updating status:", error);
      swal("Error", t("Error updating status"), "error");
    }
  };

  return (
    <DashboardAdmin>
      <div className={classes.root}>
        <div className={classes.header}>
          <Typography variant="h4" color="textSecondary">
            {t("Configuration Management")}
          </Typography>
        </div>

        <Alert severity="warning" className={classes.warning}>
          <strong>{t("Warning")}:</strong>{" "}
          {t(
            "Changes to these configurations may affect critical system features including:"
          )}
          <ul>
            <li>{t("Payment gateway integrations")}</li>
            <li>{t("AI-powered features and recommendations")}</li>
            <li>{t("Platform default language settings")}</li>
            <li>{t("System-wide behavior and functionality")}</li>
          </ul>
          {t(
            "Please ensure you understand the impact before making any changes."
          )}
        </Alert>

        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableHeader}>
                <TableCell>{t("Configuration Name")}</TableCell>
                <TableCell>{t("Value")}</TableCell>
                <TableCell>{t("Status")}</TableCell>
                <TableCell align="right">{t("Actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {configurations.map((config) => (
                <TableRow key={config.id} hover>
                  <TableCell>
                    <strong>{config.config_name}</strong>
                  </TableCell>
                  <TableCell>{config.config_value}</TableCell>
                  <TableCell>
                    <StatusChip status={config.status} />
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      className={classes.actionButton}
                      color="primary"
                      size="small"
                      onClick={() => handleEditClick(config)}
                    >
                      <EditIcon />
                    </IconButton>
                    <Button
                      variant="contained"
                      color={
                        config.status === "enabled" ? "secondary" : "primary"
                      }
                      onClick={() => handleToggleStatus(config)}
                      className={classes.toggleButton}
                      size="small"
                    >
                      {config.status === "enabled" ? t("Disable") : t("Enable")}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          maxWidth="sm"
          fullWidth
          className={classes.dialog}
        >
          <DialogTitle className={classes.dialogTitle}>
            <Typography variant="h6" color="primary">
              {t("Edit Configuration")}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label={t("Configuration Value")}
              type="text"
              fullWidth
              value={editedValue}
              onChange={(e) => setEditedValue(e.target.value)}
              variant="outlined"
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseDialog}
              variant="outlined"
              color="primary"
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={handleUpdateConfiguration}
              variant="contained"
              color="primary"
            >
              {t("Save")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </DashboardAdmin>
  );
}
