import React, { useState } from "react";
import { Button, TextField, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import useUserState from "../../hooks/useUserState";
import useBDocTranslator from "../../hooks/useBDocTranslator";
import Axios from "axios";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function BackgroundFormEHR(props) {
  const [content, setContent] = useState("");
  const userLocalState = useUserState();
  const { t } = useBDocTranslator();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleSave = () => {
    if (!content) return;

    // remove the html tags from the content
    const contentWithoutTags = content.replace(/<[^>]*>?/g, "");

    Axios.post(
      `${process.env.REACT_APP_API_URL_BASE}/bg/create`,
      {
        content: contentWithoutTags,
        type: props.type,
        patient_id: props.patientId,
      },
      {
        headers: { Authorization: "Bearer " + userLocalState[0].jwt },
      }
    )
      .then((res) => {
        setSnackbarMessage(t("Background information saved successfully"));
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
        props.handleRefreshData();
        setContent("");
      })
      .catch((error) => {
        console.log(error);
        setSnackbarMessage(t("Error saving background information"));
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <div
      style={{ height: "fit-content" }}
      className="shadow p-3 m-2 bg-white rounded-lg col-md-12 col-sm-12 position-relative d-flex flex-column justify-content-between"
    >
      <div className="col-sm-12 col-md-12 mt-1 text-center">
        <p className="font-weight-bold">{props.responseMessage}</p>
      </div>

      <div className="pb-5">
        <ReactQuill
          theme="snow"
          value={content}
          onChange={setContent}
          style={{ height: "200px" }}
          placeholder={t("Enter background information here...")}
        />
      </div>

      <div className="mt-4">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          className="col-md-6"
          disabled={!content}
        >
          {t("Save")}
        </Button>
      </div>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
