import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useSnackbar } from "notistack";
import { startOfWeek, format, addDays } from "date-fns";
import { es } from "date-fns/locale";
import {
  Backdrop,
  CircularProgress,
  makeStyles,
  Button,
} from "@material-ui/core";
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  Cancel as CancelIcon,
} from "@material-ui/icons";
import PlannerMenuTab from "../planner/PlannerMenuTab";
import PlannerCalendar from "../planner/PlannerCalendar";
import useBDocTranslator from "../hooks/useBDocTranslator";
import swal from "sweetalert";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
}));

export default function AdminProviderPlanner({
  providerId,
  jwt,
  providerLocations,
}) {
  const [slots, setSlots] = useState([]);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [events, setEvents] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useBDocTranslator();
  const [calendarSelection, setCalendarSelection] = useState(1);

  useEffect(() => {
    fetchSchedule();
  }, [providerId]);

  useEffect(() => {
    if (slots.length > 0) {
      handleSave();
    }
  }, [slots]);

  const handleClose = () => {
    setOpen(false);
  };

  const getDayName = (date) => {
    return format(date, "EEE", { locale: es });
  };

  const fetchSchedule = () => {
    if (!providerId) return;

    setOpen(true);
    Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/sheduleProvider/findByProviderId?providerId=${providerId}`,
      {
        headers: { Authorization: "Bearer " + jwt },
      }
    )
      .then((response) => {
        if (response?.data?.length > 0) {
          const eventParsed = parseEvents(response.data);
          setEvents(eventParsed);
        }
        setOpen(false);
      })
      .catch((error) => {
        console.error("Error fetching schedule:", error);
        setOpen(false);
        enqueueSnackbar(t("Error loading schedule"), { variant: "error" });
      });
  };

  const handleSave = () => {
    setOpen(true);
    const params = prepareParams();
    if (!params) {
      setOpen(false);
      return;
    }

    Axios.post(
      `${process.env.REACT_APP_API_URL_BASE}/sheduleProvider/create`,
      params[0],
      {
        headers: { Authorization: "Bearer " + jwt },
      }
    )
      .then((res) => {
        setOpen(false);
        fetchSchedule();
        enqueueSnackbar(t("Slot time Saved!"), {
          variant: "success",
        });
      })
      .catch((err) => {
        console.error("Error saving schedule:", err);
        setOpen(false);
        enqueueSnackbar(t("Error saving schedule"), { variant: "error" });
      });
  };

  const handleUnSelect = (id) => {
    if (id) {
      swal({
        title: t("Are you sure you want to delete this Slot Selected?"),
        text: t(
          "remember that you can select the availabilty block that you want"
        ),
        icon: "warning",
        buttons: true,
        dangerMode: false,
      }).then((ok) => {
        if (ok) {
          setOpen(true);
          Axios.delete(
            `${process.env.REACT_APP_API_URL_BASE}/sheduleProvider/delete?id=${id}`,
            {
              headers: { Authorization: "Bearer " + jwt },
            }
          )
            .then((res) => {
              setOpen(false);
              fetchSchedule();
              enqueueSnackbar(t("Slot time deleted!"), {
                variant: "success",
              });
            })
            .catch((err) => {
              console.error("Error deleting slot:", err);
              setOpen(false);
              enqueueSnackbar(t("Error deleting slot"), { variant: "error" });
            });
        }
      });
    }
  };

  const prepareParams = () => {
    const resultArr = [];
    if (slots.length > 0) {
      slots.forEach((item) => {
        item.formattedTimes.provider_id = providerId;
        item.formattedTimes.practice_location_id = calendarSelection;
        resultArr.push(item.formattedTimes);
      });
      return resultArr;
    }
  };

  const parseEvents = (objToParse) => {
    let result = [];
    if (objToParse) {
      objToParse.forEach((item) => {
        let dayStart = retreiveDate(item.week_day);
        let start = new Date(dayStart);
        start.setHours(
          item.time_start.split(":")[0],
          item.time_start.split(":")[1]
        );

        let dayEnd = retreiveDate(item.week_day);
        let end = new Date(dayEnd);
        end.setHours(item.time_end.split(":")[0], item.time_end.split(":")[1]);

        const obj = {
          id: item.id,
          start,
          end,
          backgroundColor:
            item?.practice_location_id === 1 ? "#257CEA" : "#12A615",
          practice_location_id: item.practice_location_id,
        };

        result.push(obj);
      });
    }
    return result;
  };

  const retreiveDate = (compareTo) => {
    const today = new Date();
    const firstDayWeek = startOfWeek(today, {
      weekStartsOn: 0,
      locale: "en-US",
    });

    for (let i = 0; i <= 6; i++) {
      const newDate = addDays(new Date(firstDayWeek), i);
      if (compareTo === i) {
        return format(newDate, "MM-dd-yyyy");
      }
    }
  };

  const filterEvents = (events, locationId) => {
    return events.filter((item) => item.practice_location_id === locationId);
  };

  return (
    <div className="row bg-white p-2">
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className="col-md-12">
        <PlannerMenuTab
          calendarSelection={calendarSelection}
          setCalendarSelection={setCalendarSelection}
          calendarsAvailable={providerLocations}
        />
      </div>

      <div className="col-md-12">
        <PlannerCalendar
          handleUnSelect={handleUnSelect}
          getDayName={getDayName}
          setSlots={setSlots}
          events={filterEvents(events, calendarSelection)}
          readOnly={false}
        />
      </div>
    </div>
  );
}
