import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Axios from "axios";
import { CSVLink } from "react-csv";
import { makeStyles } from "@material-ui/core/styles";
import DashboardMui from "../../dashboard/DashboardMui";
import ImproveIncomeIcon from "../../../images/improve-incomes.svg";
import useUserState from "../../hooks/useUserState";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@material-ui/core";
import useTranslator from "../../hooks/useBDocTranslator";
import swal from "sweetalert";

const useStyles = makeStyles((theme) => ({
  datePicker: {
    margin: theme.spacing(2),
  },
  chartContainer: {
    padding: theme.spacing(2),
    paddingTop: 50,
    marginTop: theme.spacing(2),
    position: "relative",
  },
  totalIncome: {
    position: "absolute",
    top: 10,
    right: 10,
    fontWeight: "bold",
    fontSize: "1.2rem",
    paddingBottom: 5,
  },
  totalAppointments: {
    position: "absolute",
    top: 10,
    fontWeight: "bold",
    fontSize: "1.2rem",
    paddingBottom: 5,
  },
  tableContainer: {
    marginTop: theme.spacing(4),
  },
  table: {
    minWidth: 650,
  },
}));

const AppointmentsIncomeReport = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(new Date() - 30);
  const [endDate, setEndDate] = useState(new Date());
  const localState = useUserState();
  const [totalIncome, setTotalIncome] = useState(0);
  const { t, changeDirectLanguage } = useTranslator();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const getData = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_API_URL_BASE}/reports/appointments/income`,
        {
          headers: { Authorization: `Bearer ${localState[0].jwt}` },
          params: {
            startDate,
            endDate,
            providerId: localState[0].extendedData.id, // Pass provider ID
          },
        }
      );
      // change the name of the keys to total_Ingreso and total_Citas
      const newData = response.data.map((item) => ({
        date: item.date,
        total_Ingreso: item.totalIncome,
        total_Citas: item.totalAppointments,
      }));
      setData(newData);

      const total = newData.reduce(
        (acc, item) => acc + (Number(item.total_Ingreso) || 0),
        0
      );
      setTotalIncome(total);
    } catch (error) {
      console.error("Error fetching data:", error);
      // if error is 404 show error message
      if (error?.response?.status === 404) {
        swal(
          "Error",
          `Error fetching data: ${error?.response?.data?.message}`,
          "warning"
        );
      } else {
        swal("Error", "Error fetching data", "error");
      }
    }
  };

  useEffect(() => {
    getData();
    changeDirectLanguage();
  }, [startDate, endDate]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <DashboardMui
      sectionTitle={t("Appointments Income Report")}
      description={t("View the income generated from appointments over time.")}
      icon={ImproveIncomeIcon}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          className={classes.datePicker}
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          label={t("Start Date")}
          value={startDate}
          onChange={(date) => setStartDate(date)}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
        <KeyboardDatePicker
          className={classes.datePicker}
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          label={t("End Date")}
          value={endDate}
          onChange={(date) => setEndDate(date)}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
        <Button variant="contained" color="primary" onClick={getData}>
          {t("Apply Filter")}
        </Button>
      </MuiPickersUtilsProvider>

      <Paper className={classes.chartContainer} elevation={3}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography className={classes.totalIncome}>
            Total Ingresos: ${Number(totalIncome).toFixed(2)}
          </Typography>
          <Typography className={classes.totalAppointments}>
            Total Citas: {data.reduce((acc, item) => acc + item.total_Citas, 0)}
          </Typography>
        </div>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="total_Ingreso" fill="#8884d8" />
            <Bar dataKey="total_Citas" fill="#10cfc9" />
          </BarChart>
        </ResponsiveContainer>
      </Paper>

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} aria-label="appointments income table">
          <TableHead>
            <TableRow>
              <TableCell>{t("Date")}</TableCell>
              <TableCell align="right">{t("Total Income")}</TableCell>
              <TableCell align="right">{t("Total Appointments")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.date}>
                  <TableCell component="th" scope="row">
                    {row?.date}
                  </TableCell>
                  <TableCell align="right">${row?.total_Ingreso}</TableCell>
                  <TableCell align="right">{row?.total_Citas}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <CSVLink data={data}>Download CSV</CSVLink>
    </DashboardMui>
  );
};

export default AppointmentsIncomeReport;
